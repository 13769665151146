import { useMemo, useState } from 'react'
// routes
import { useQuery } from '@apollo/client'
import { paths } from 'src/routes/paths'
// locales
import { useLocales } from 'src/locales'
// components
import SvgColor from 'src/components/svg-color'
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import { PostAuthorFilter } from 'src/types/feed'
import { GET_NAVIGATION_QUERY, getMeetingSummaryDrafts } from 'src/graphql/queries'
import { NavSectionProps } from 'src/components/nav-section/types'
import { useFeatureFlag } from 'src/hooks/use-feature-flag'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
)

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  schedule: <Iconify icon="fluent:calendar-24-filled" width={30} />,
  meetingSeries: <Iconify icon="tabler:calendar-event" width={30} />,
  meetingOneOnOne: <Iconify icon="tabler:users" width={30} />,
  meetingTeam: <Iconify icon="tabler:users-group" width={30} />,
  meetingExternal: <Iconify icon="tabler:world" width={30} />,
}

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales()
  const [draftsBadge, setDraftsBadge] = useState<string | undefined>(undefined)
  useQuery(getMeetingSummaryDrafts, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { meetingSummaryDrafts } = data
      if (meetingSummaryDrafts && meetingSummaryDrafts.length > 0) {
        setDraftsBadge(meetingSummaryDrafts.length.toString())
      } else {
        setDraftsBadge(undefined)
      }
    },
  })
  const { data: meData } = useQuery(GET_NAVIGATION_QUERY, {
    fetchPolicy: 'cache-first',
  })
  const showSeriesV2 = useFeatureFlag('meeting_series_v2')

  const data = useMemo<NavSectionProps['data']>(() => {
    const getFilteredMeetingSeries = (category: string) =>
      meData?.getNavigation.recurringMeetings
        .filter((meeting) => meeting.category === category)
        .map((meeting) => ({
          title: meeting.title,
          path: paths.dashboard.meetingSeries.details(meeting.id),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)) ?? []

    const oneToOnes = getFilteredMeetingSeries('1to1')
    const teamMeetings = getFilteredMeetingSeries('team')
    const externalMeetings = getFilteredMeetingSeries('external')

    const meetingSeriesV2: NavSectionProps['data'] = showSeriesV2
      ? [
          {
            subheader: 'Meeting Series',
            items: [
              {
                title: 'View All',
                variant: 'small',
                onlyShowForNavSize: 'vertical',
                path: paths.dashboard.meetingSeries.listv2(),
              },
              {
                title: 'Meeting Series',
                icon: ICONS.meetingSeries,
                onlyShowForNavSize: 'mini',
                path: paths.dashboard.meetingSeries.listv2(),
              },
              {
                title: '1:1s',
                path: paths.dashboard.meetingSeries.listv2('1to1'),
                icon: ICONS.meetingOneOnOne,
                alwaysStartOpen: true,
                children: oneToOnes,
                clickableWithChildren: true,
              },
              {
                title: 'Team Meetings',
                path: paths.dashboard.meetingSeries.listv2('team'),
                icon: ICONS.meetingTeam,
                alwaysStartOpen: true,
                children: teamMeetings,
                clickableWithChildren: true,
              },
              {
                title: 'External',
                path: paths.dashboard.meetingSeries.listv2('external'),
                icon: ICONS.meetingExternal,
                alwaysStartOpen: true,
                children: externalMeetings,
                clickableWithChildren: true,
              },
            ],
          },
        ]
      : []
    const results = [
      {
        subheader: ' ',
        items: [
          {
            title: t('dashboard'),
            path: paths.dashboard.home,
            icon: ICONS.dashboard,
          },
          // Projects
          {
            title: 'Projects',
            icon: ICONS.folder,
            path: paths.dashboard.projects.root,
            alwaysStartOpen: true,
          },
          // MEETING Series
          {
            title: t('nav.meeting_series'),
            icon: ICONS.calendar,
            path: paths.dashboard.meetingSeries.list,
            alwaysStartOpen: true,
          },
          // FEED
          {
            title: 'Feed',
            path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.companyWide),
            icon: ICONS.blog,
            alwaysStartOpen: true,
            badgeText: draftsBadge,
            clickableWithChildren: true,
            children: [
              { title: t('nav.latest'), path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.companyWide) },
              {
                title: t('nav.drafts'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.drafts),
                info: <DraftLabel />,
              },
              {
                title: t('nav.my_meetings'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.myMeetings),
              },
              {
                title: t('nav.my_emails'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.myEmails),
              },
              {
                title: t('nav.shared_with_me'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.sharedWithMe),
              },
            ],
          },
        ],
      },
    ]
    return [...results, ...meetingSeriesV2]
  }, [draftsBadge, meData?.getNavigation.recurringMeetings, showSeriesV2, t])

  return data
}

/**
 * A label that shows the number of meeting summary drafts.
 * @returns
 */
function DraftLabel() {
  const { data } = useQuery(getMeetingSummaryDrafts, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.meetingSummaryDrafts?.length) {
    return null
  }

  return (
    <Label color="error" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      {data.meetingSummaryDrafts.length}
    </Label>
  )
}
